import React from "react"
import { AnimatedListLink} from "../components/listlink"
import NightModeToggle from "../components/nightmodetoggle"
import { Link } from "gatsby"

export default function Header(props) {
  return (

    <header>

    <div>

    <Link to="/" className="logo"><img src="/images/logo.svg"  alt="rachel.codes logo" title="rachel.codes" /></Link>
    <Link to="/" className="logo logo-white"><img src="/images/logo-white.svg"  alt="rachel.codes logo" title="rachel.codes" /></Link>
    <div>

    <NightModeToggle />
    </div>
    </div>


    </header>
  );
}
