import React from 'react';

import Header from '../components/header';

const HeaderWrapper = ({ children }) => (
  <>

    <Header />
        {children}
  </>
);

export default HeaderWrapper;
