import React, { useState } from "react";
import Switch from '@material-ui/core/Switch';
import Helmet from 'react-helmet'


export default function NightModeToggle(props) {
  const [isNight, setIsNight] = useState(false)

  return (

  <div className="nightmode-toggle">
  <Helmet>
    <title>rachel.codes - Freelance Web Developer in Brighton</title>
    <meta name="description" content="Portfolio website for rachel.codes a Freelance Web Developer in Brighton" />
    <body className={`${isNight ? "night" : ""}`} />
  </Helmet>
    <Switch color="primary" inputProps={{ 'aria-label': 'Nightmode Toggle' }} onChange={(e, c) => setIsNight(e.target.checked) } />
    <i className="nightmode-icon"></i>

  </div>
  );
}
